<div *ngIf="loading" class="full-screen-center" ngClass="loading">
  <div class="text-primary" role="status">
      <img [src]="'assets/Logo/spinner-border.gif'" alt="Spinner" id="spinner">
  </div>
</div>
<div #mainCalendar>
    <app-calendar [role]="role" [functionality]="'patient-details'" [assignedEvents]="calendarEvents"
        [patientAppointments]="patientAppointments" (exerciseClicked)="getExerciseDetails($event, calendarEventModal)"
        (dayClicked)="getDayDetails($event, calendarDayModal)"
        (createAppointmentClick)="openCreateAppointmentModal2(createAppointmentModal)"
        (viewAppointmentClick)="openAppointmentModal($event, viewAppointmentModal)"
        (updateAppointmentClick)="openAppointmentModal($event, updateAppointmentModal)"
        (cancelAppointmentClick)="openAppointmentModal($event, cancelAppointmentModal)">
    </app-calendar>

    <div *ngIf="role === 'physiotherapist'" class="d-flex justify-content-end mt-3">
        <button class="btn btn-sm btn-primary text-white" (click)="navigateTo('create-program')">
            {{ "createExerciseProgram.createExerciseProgramText" | translate }}
        </button>
    </div>
</div>





<ng-template #updateAppointmentModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">{{ "patientDetails.appointment.update" | translate }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <app-calendar [functionality]="'appointment-selection'" [allAppointments]="allAppointments"
      [patientAppointments]="patientAppointments"
      [selectedAppointmentSlot]="{ date: selectedAppointment.date, time: selectedAppointment.time }"
      (dateSelect)="appointmentSelect($event)">
    </app-calendar>
    <div class="d-flex flex-row align-items-center mt-3">
      <span>{{ "patientDetails.appointment.note" | translate }}</span>:
      <input type="text" class="form-control ml-2" trim="blur" [(ngModel)]="selectedAppointment.note"
        (change)="appointmentNoteEdited = true" />
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-primary" [disabled]="!appointmentDateSelected && !appointmentNoteEdited"
      (click)="updateAppointment(); modal.close()">
      {{ 'generalTerms.update' | translate }}
    </button>
    <button type="button" class="btn btn-sm btn-secondary" (click)="modal.close()">
      {{ 'generalTerms.cancel' | translate }}
    </button>
  </div>
</ng-template>


<ng-template #cancelAppointmentModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">
      {{ "patientDetails.appointment.cancel" | translate }}
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ "patientDetails.appointment.cancelQuestionText"| translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-primary" (click)="modal.close(); cancelAppointment()">
      {{ "generalTerms.yes" | translate }}
    </button>
    <button type="button" class="btn btn-sm btn-secondary" (click)="modal.close()">
      {{ "generalTerms.no" | translate }}
    </button>
  </div>
</ng-template>
<ng-template #viewAppointmentModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title">{{ "patientDetails.appointment.title" | translate }} - {{
        selectedAppointment.formated_date }}</h5>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="d-flex flex-column">
        <div class="d-flex align-items-center mb-2">
          <h6 *ngIf="role === 'physiotherapist'" class="m-0">{{ "home.bottomNavigation.patient" | translate }}:</h6>
          <label class="m-0">&nbsp; {{ selectedAppointment.name }}</label>
        </div>
        <div class="d-flex align-items-center mb-2">
          <h6 class="m-0">{{ "patientDetails.appointment.time" | translate }}:</h6>
          <label class="m-0">&nbsp; {{ selectedAppointment.formated_time }}</label>
        </div>
        <div *ngIf="selectedAppointment.note" class="d-flex align-items-center mb-2">
          <h6 class="m-0">{{ "patientDetails.appointment.note" | translate }}:</h6>
          <label class="m-0">&nbsp; {{ selectedAppointment.note }}</label>
        </div>
        <div class="d-flex align-items-center">
          <h6 class="m-0">{{ "patientDetails.appointment.link" | translate }}:</h6>
          <label class="m-0">&nbsp;
            <a target="_blank" [href]="role === 'physiotherapist' ? selectedAppointment.physio_url : selectedAppointment.patient_url">
              {{ "patientDetails.appointment.joinMeeting" | translate }}
            </a>
          </label>
        </div>
      </div>
    </div>
  </ng-template>


<ng-template #createAppointmentModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title">{{ "patientDetails.appointment.create" | translate }}</h5>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <app-calendar [functionality]="'appointment-selection'" [allAppointments]="allAppointments"
        [patientAppointments]="patientAppointments" (dateSelect)="appointmentSelect($event)">
      </app-calendar>
      <div class="d-flex flex-row align-items-center mt-3">
        <span>{{ "patientDetails.appointment.note" | translate }}</span>:
        <input type="text" class="form-control ml-2" trim="blur" [(ngModel)]="newAppointmentNote" />
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-sm btn-primary" [disabled]="!appointmentDateSelected"
        (click)="createAppointment(); modal.close()">
        {{ 'generalTerms.create' | translate }}
      </button>
      <button type="button" class="btn btn-sm btn-secondary" (click)="modal.close()">
        {{ 'generalTerms.cancel' | translate }}
      </button>
    </div>
  </ng-template>

<ng-template #calendarDayModal let-modal>
    <div class="modal-header pb-0" style="border-bottom: none">
      <h5 class="modal-title">{{ "patientDetails.exercises" | translate }} - {{ titleDate }}</h5>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body pt-3">
      <div *ngIf="!exercisesForDayEmpty">
        <div *ngFor="let period of expandedPeriodsObj">
          <hr style="background-color: #EAECF3; margin: 0 0 5px 0;">
          <div class="d-flex justify-content-between mb-1">
            <span>
              <span style="color: #000; font-weight: 700;">{{ period.name | translate }}</span>
              ({{ exercisesForDay[period.id]?.length || 0 }})
            </span>
            <div (click)="expandedPeriods[period.id] = !expandedPeriods[period.id]" style="cursor: pointer;">
              <img [src]="'assets/img/svg/icons/arrow-up.svg'" *ngIf="expandedPeriods[period.id]">
              <img [src]="'assets/img/svg/icons/arrow-dovn.svg'" *ngIf="!expandedPeriods[period.id]">
            </div>
          </div>
          <div *ngIf="expandedPeriods[period.id] && exercisesForDay[period.id]?.length">
            <div *ngFor="let exercise of exercisesForDay[period.id]">
              <div [ngStyle]="exerciseColor(exercise?.meta?.performed, titleDate, formattedDate)"
                style="margin-bottom: 5px; padding: 0 27px; border-radius: 8px; cursor: pointer;"
                (click)="handleExerciseFromDay(exercise, calendarEventModal, modal)">
                <span style="color: #314F6D; font-weight: 700;">
                  {{ getNameWithoutPeriod(exercise.title) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div *ngIf="titleDate >= formattedDate">
          <div *ngIf="exercisesForDayEmpty">
            <div class="text-center">
              <p class="h5 text-muted mb-2">
                {{ "patientDetails.calendarFuture" | translate }}
              </p>
            </div>
          </div>
        </div>
        <div *ngIf="titleDate < formattedDate">
          <div *ngIf="exercisesForDayEmpty">
            <div class="text-center">
              <p class="h5 text-muted mb-2">
                {{ "patientDetails.calendarPast" | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

<ng-template #calendarEventModal let-modal>
    <div class="modal-header">
      <i *ngIf="exerciseFromDay" class="fa fa-chevron-left fa-2x mr-4" aria-hidden="true"
        style="color: #384f6b; cursor: pointer" (click)="handleBackToDay(exercise.start, modal, calendarDayModal)"></i>
      <h5 class="modal-title">{{ exercise.title }} - {{ titleDate }}</h5>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button>
    </div>

    <div class="modal-body">
      <div [ngSwitch]="exerciseResults">
        <div *ngSwitchCase="'exists'">
          <div *ngIf="!exerciseResultsError">
            <ul ngbNav #nav="ngbNav" [(activeId)]="activeResultTab" class="nav-tabs">
              <li [ngbNavItem]="1">
                <button ngbNavLink>{{ 'patientDetails.performanceResults' | translate }}</button>
                <ng-template ngbNavContent>
                  <div *ngIf="!exerciseResultsError && exerciseResultsLoaded">
                    <div *ngIf="['breathing', 'relaxation' ,'breathing_time'].includes(exerciseCategory)">
                      <div *ngIf="exerciseDetails.display" class="mt-3 ml-3">
                        <h4>{{ "patientDetails.exerciseDetails" | translate }}</h4>
                        <div class="row d-flex mx-3 justify-content-center">
                          <h5 *ngIf="sets > 1" class="col-lg-3 col-md-3 col-sm-6 mx-auto font-weight-light">
                            {{ "createExerciseProgram.sets" | translate }}:
                            {{ sets }}
                          </h5>
                          <h5 *ngIf="sets > 1 && setBreak > 0"
                            class="col-lg-3 col-md-3 col-sm-6 mx-auto font-weight-light">
                            {{ "createExerciseProgram.break" | translate }}:
                            {{ setBreak }} s
                          </h5>
                          <h5 class="col-lg-3 col-md-3 col-sm-6 mx-auto font-weight-light">
                            {{ "patientDetails.inhale" | translate }}:
                            {{ exerciseDetails.inhale_duration }} s
                          </h5>
                          <h5 class="col-lg-3 col-md-3 col-sm-6 mx-auto font-weight-light">
                            {{ "patientDetails.exhale" | translate }}:
                            {{ exerciseDetails.exhale_duration }} s
                          </h5>
                        </div>
                      </div>

                      <h4 *ngIf="exerciseCategory === 'breathing' || exerciseCategory === 'abdominal'"
                        class="mt-4 ml-3">
                        {{ "patientDetails.breathingResults" | translate }}
                      </h4>
                      <div *ngIf="exerciseCategory === 'breathing' || exerciseCategory === 'abdominal'"
                        class="row justify-content-around" style="margin-bottom: 6%">
                        <div class="col-lg-3 col-md-6 col-sm-12 mx-auto mb-lg-0 mb-md-2 mb-sm-0">
                          <circle-progress [percent]="inhale" [maxPercent]="100" [subtitle]="inhale + '%'"
                            [subtitleColor]="getDotColor(inhale)"
                            [outerStrokeColor]="getDotColor(inhale)"></circle-progress>
                          <h5 class="pie-chart" style="justify-self: center;">
                            {{ "patientDetails.inhale" | translate }}
                          </h5>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12 mx-auto mb-lg-0 mb-md-2 mb-sm-0">
                          <circle-progress [percent]="exhale" [maxPercent]="100" [subtitle]="exhale + '%'"
                            [subtitleColor]="getDotColor(exhale)"
                            [outerStrokeColor]="getDotColor(exhale)"></circle-progress>
                          <h5 class="pie-chart" style="justify-self: center;">
                            {{ "patientDetails.exhale" | translate }}
                          </h5>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12 mx-auto mb-lg-0 mb-md-2 mb-sm-0">
                          <circle-progress [percent]="overall" [maxPercent]="100" [subtitle]="overall + '%'"
                            [subtitleColor]="getDotColor(overall)"
                            [outerStrokeColor]="getDotColor(overall)"></circle-progress>
                          <h5 class="pie-chart" style="justify-self: center;">
                            {{ "patientDetails.overall" | translate }}
                          </h5>
                        </div>
                      </div>
                      <div class="row justify-content-around" style="margin-bottom: 6%">
                        <div *ngIf="this.posture || this.posture === 0"
                          class="col-lg-3 col-md-6 col-sm-12 mx-auto mb-lg-0 mb-md-2 mb-sm-0">
                          <circle-progress [percent]="this.posture" [maxPercent]="100" [subtitle]="this.posture + '%'"
                            [subtitleColor]="getDotColor(this.posture)"
                            [outerStrokeColor]="getDotColor(this.posture)"></circle-progress>
                          <h5 class="pie-chart" style="justify-self: center;">
                            {{ "patientDetails.posture" | translate }}
                          </h5>
                        </div>
                        <div *ngIf="this.shoulder || this.shoulder === 0"
                          class="col-lg-3 col-md-6 col-sm-12 mx-auto mb-lg-0 mb-md-2 mb-sm-0">
                          <circle-progress [percent]="this.shoulder" [maxPercent]="100" [subtitle]="this.shoulder + '%'"
                            [subtitleColor]="getDotColor(this.shoulder)"
                            [outerStrokeColor]="getDotColor(this.shoulder)"></circle-progress>
                          <h5 class="pie-chart" style="justify-self: center;">
                            {{ "patientDetails.shoulder" | translate }}
                          </h5>
                        </div>
                      </div>
                      <div *ngIf="this.countUnitMeanValue > 0 && exerciseCategory !== 'abdominal'" class="mt-5"
                        style="display: inline-flex">
                        <span class="dot" [style.background-color]="
                              getDotColor(this.countUnitMeanValue)
                            "></span>
                        <h5>
                          &nbsp;&nbsp;
                          {{ "exercise.detectionConfidence" | translate }}:
                          {{ this.countUnitMeanValue }}%
                        </h5>
                      </div>
                    </div>
                    <div *ngIf="exerciseCategory=='physical'">
                      <div *ngIf="aiTrainerDisabled" class="d-flex mt-3">
                        <img [src]="'assets/images/exercise-icons/warning-icon.png'" alt="Image placeholder"
                          height="30" width="auto" />
                        <h5 class="my-auto pl-2">{{ 'patientDetails.aiTrainerWasDisabledReasons.' +
                          aiTrainerDisabledReason | translate }}</h5>
                      </div>
                      <div *ngIf="sets > 0 || repetitions > -1 || count > -1" class="mt-3 mx-3">
                        <h4>{{ "patientDetails.exerciseDetails" | translate }}</h4>
                        <div class="row m-0 justify-content-center">
                          <h5 *ngIf="sets > 0" class="text-center font-weight-light mr-4">
                            {{ "createExerciseProgram.sets" | translate }}: {{ sets }}
                          </h5>
                          <h5 *ngIf="sets > 0 && setBreak > 0" class="text-center font-weight-light mr-4">
                            {{ "createExerciseProgram.break" | translate }}: {{ setBreak }} s
                          </h5>
                          <h5 *ngIf="repetitions > -1" class="text-center font-weight-light mr-4">
                            {{ "createExerciseProgram.repetitions" | translate }}: {{ repetitions }}
                          </h5>
                          <h5 *ngIf="count > -1" class="text-center font-weight-light mr-4">
                            {{ "exercise.count" | translate }}: {{ count }}
                          </h5>
                        </div>
                      </div>
                      <h4 class="mt-4 ml-3">
                        {{ "exercise.results" | translate }}:
                      </h4>
                      <div style="margin-top: 0%">

                        <div class="row justify-content-center">
                          <div *ngFor="let result of countDisplayNames; let i = index"
                            class="col-lg-3 col-md-6 col-sm-12 mx-auto mb-lg-0 mb-md-2 mb-sm-0" style="margin-top: 0%">
                            <circle-progress [percent]="
                                  (this.countValues[i] / this.countTotal[i]) * 100
                                " [maxPercent]="100" [subtitle]="
                                  this.countValues[i] + '/' + this.countTotal[i]
                                " [subtitleColor]="
                                  convertToPercentage(
                                    this.countValues[i],
                                    this.countTotal[i]
                                  )
                                " [outerStrokeColor]="
                                  convertToPercentage(
                                    this.countValues[i],
                                    this.countTotal[i]
                                  )
                                "></circle-progress>
                            <h5 class="pie-chart">
                              {{ "exercise." + result | translate }}
                            </h5>
                          </div>
                        </div>
                      </div>

                      <div style="margin-top: 2%">

                        <div class="row justify-content-center">
                          <div *ngFor="
                                let result of countUnitDisplayNames;
                                let i = index
                              " class="col-lg-3 col-md-6 col-sm-12 mx-auto mb-lg-0 mb-md-2 mb-sm-0"
                            style="margin-top: 0%">
                            <circle-progress [percent]="
                                  (this.countUnitValues[i] /
                                    this.countUnitValues[i]) *
                                  100
                                " [maxPercent]="100" [subtitle]="this.countUnitValues[i]" [subtitleColor]="'#152c5b'"
                              [outerStrokeColor]="'#152c5b'"></circle-progress>
                            <h5 class="pie-chart">
                              {{ "exercise." + result | translate }}
                            </h5>
                          </div>
                        </div>
                      </div>

                      <div style="margin-top: 2%; margin-bottom: 5%">

                        <div class="row justify-content-center">
                          <div *ngFor="let result of percentDisplayName; let i = index"
                            class="col-lg-3 col-md-6 col-sm-12 mx-auto mb-lg-0 mb-md-2 mb-sm-0" style="margin-top: 0%">
                            <circle-progress [percent]="percentValue[i]" [maxPercent]="100"
                              [subtitle]="this.percentValue[i] + '%'" [subtitleColor]="getDotColor(percentValue[i])"
                              [outerStrokeColor]="getDotColor(percentValue[i])"></circle-progress>
                            <h5 class="pie-chart">
                              {{ "exercise." + result | translate }}
                            </h5>
                          </div>
                        </div>
                      </div>



                      <div>
                        <!-- <div class="row" *ngIf=" dataSources && dataSources.length >0">
                                                  <h2>Degree Analysis: </h2>
                                                  <hr>
                                              </div> -->
                        <div class="row nopadding justify-content-center">
                          <div *ngFor="let dataSource of dataSources; let i = index"
                            class="col-lg-6 col-md-12 col-sm-12 mx-auto text-center nopadding" style="margin-top: 0%">
                            <fusioncharts type="angulargauge" height="250" dataFormat="json" [dataSource]="dataSource">
                            </fusioncharts>
                            <h5 class="pie-chart">
                              {{ "exercise." + degreeDisplayName[i] | translate }}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="problemDisplayNameNotNull.length > 0" class="mb-5">
                        <h4 class="mt-6 ml-3">
                          {{ "exercise.problems" | translate }}:
                        </h4>
                        <div class="row justify-content-center">
                          <div *ngFor="
                                let result of problemDisplayNameNotNull;
                                let i = index
                              " class="col-lg-3 col-md-6 col-sm-12 mx-auto mb-lg-0 mb-md-2 mb-sm-0"
                            style="margin-top: 0%">
                            <circle-progress [percent]="
                                  (this.problemCountValueNotNull[i] /
                                    this.problemTotalValueNotNull[i]) *
                                  100
                                " [maxPercent]="100" [subtitle]="
                                  this.problemCountValueNotNull[i] +
                                  '/' +
                                  this.problemTotalValueNotNull[i]
                                " [subtitleColor]="'#823335'" [outerStrokeColor]="'#823335'"></circle-progress>
                            <h5 class="pie-chart">
                              {{ "exercise." + result | translate }}
                            </h5>
                          </div>
                        </div>

                      </div>

                      <div *ngIf="this.countUnitMeanValue > 0" class="mt-5" style="display: inline-flex">
                        <span class="dot" [style.background-color]="
                              getDotColor(this.countUnitMeanValue)
                            "></span>
                        <h5>
                          &nbsp;&nbsp;
                          {{ "exercise.detectionConfidence" | translate }}:
                          {{ this.countUnitMeanValue }}%
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="!exerciseResultsLoaded"
                    class="preloader d-flex justify-content-center align-items-center my-5">
                    <div class="spinner-border text-primary" role="status ">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </ng-template>
              </li>
              <li *ngIf="exerciseCategory === 'breathing' || this.exerciseCategory == 'breathing_time'" [ngbNavItem]="2">
                <button ngbNavLink>{{ 'patientDetails.postureAnalysis' | translate }}</button>
                <ng-template ngbNavContent>
                  <canvas baseChart class="chart" [datasets]="breathingBarChartData.data.datasets"
                    [labels]="breathingBarChartData.data.labels" [chartType]="breathingBarChartData.type"
                    [options]="breathingBarChartData.options">
                  </canvas>
                </ng-template>
              </li>
              <li *ngIf="['breathing', 'relaxation', 'breathing_time'].includes(this.exerciseCategory)" [ngbNavItem]="3">
                <button ngbNavLink>{{ 'patientDetails.breathingChart' | translate }}</button>
                <ng-template ngbNavContent>
                  <div *ngIf="breathingPlotData">
                    <app-breathing-graph [plotData]="breathingPlotData" [assignedDates]="assignedDates"
                      [patientUid]="patientId" [exerciseId]="exercise?.meta?.exercise_id"
                      [choosenDate]="exerciseDetailsDate"></app-breathing-graph>
                  </div>
                  <div *ngIf="!breathingPlotData && !breathingPlotError"
                    class="preloader d-flex justify-content-center align-items-center my-5">
                    <div class="spinner-border text-primary" role="status ">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                  <div *ngIf="!breathingPlotData && breathingPlotError" class="text-center">
                    <p class="h5 text-muted mt-6 mb-5">
                      {{ "patientDetails.insufficientData" | translate }}
                    </p>
                  </div>
                </ng-template>
              </li>
              <li *ngIf="emotion && emotion.length > 0" [ngbNavItem]="4">
                <button ngbNavLink>{{ 'patientDetails.emotions' | translate }}</button>
                <ng-template ngbNavContent>
                  <div style="justify-content: space-around;">
                    <table style="width: 100%;" mat-table [dataSource]="emotion" aria-label="Emotions">
                      <ng-container matColumnDef="instruction">
                        <th mat-header-cell *matHeaderCellDef> Instruction </th>
                        <td mat-cell *matCellDef="let emotion"> {{emotion.instruction[locale] ??
                          emotion.instruction['en'] ?? emotion.instruction}} </td>
                      </ng-container>
                      <ng-container matColumnDef="FER">
                        <th mat-header-cell *matHeaderCellDef> Emotion </th>
                        <td mat-cell *matCellDef="let emotion">
                          <img alt="Image placeholder" [src]="'assets/images/emojis/' + emotion.FER + '.png'"
                            width="30" height="auto" />
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="FERsec">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let emotion">
                          <img *ngIf="emotion.FERsec != ''" alt="Image placeholder"
                            [src]="'assets/images/emojis/' + emotion.FERsec + '.png'" width="30" height="auto" />
                        </td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                  </div>
                  <div>
                    <img alt="Image placeholder" [src]="'assets/images/question-mark.png'" id="description"
                      width="15" height="auto" />
                    {{ 'patientDetails.emotionDescription' | translate }}
                  </div>
                </ng-template>
              </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
          </div>
          <div *ngIf="exerciseResultsError" class="text-center">
            <p class="h5 text-muted mb-2">
              {{ "patientDetails.insufficientData" | translate }}
            </p>
          </div>
        </div>
        <div *ngSwitchCase="'not-performed'">
          <div class="text-center">
            <p class="h5 text-muted mb-2">
              {{ "patientDetails.exerciseNotDone" | translate }}
            </p>
          </div>
        </div>
        <div *ngSwitchCase="'today-not-performed'">
          <div class="text-center">
            <p class="h5 text-muted mb-2">
              {{ "patientDetails.notPerformedYet" | translate }}
            </p>
          </div>
        </div>
        <div *ngSwitchCase="'future-date'">
          <div class="text-center">
            <p class="h5 text-muted mb-2">
              {{ "patientDetails.futureDate" | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
